<template>
    <div class="container">
        <header>
            <router-link
                :to="{ name: 'patientRecords' }"
                custom
                v-if="!editing"
                v-slot="{ href }"
            >
                <a :href="href">
                    <i class="icon-leftarrow"></i>
                    <span>返回</span>
                </a>
            </router-link>
            <div v-else class="back-button" @click="editing = false">
                <i class="icon-leftarrow"></i>
                返回
            </div>
            <div @click="editing = true">編輯</div>
        </header>
        <edit-profile
            type="patient"
            v-if="editing"
            @save="editing = false"
        ></edit-profile>
        <show-profile type="patient" v-else></show-profile>
    </div>
</template>

<script>
import { getPatientRecord } from "@/api";
import { mapMutations } from "vuex";
import ShowProfile from "./profileComponents/showProfile.vue";
import EditProfile from "./profileComponents/editProfile.vue";
export default {
    components: {
        ShowProfile,
        EditProfile,
    },
    data: function () {
        return {
            editing: false,
        };
    },
    methods: {
        ...mapMutations({
            setPatientProfileData: "profile/setPatientProfileData",
        }),
    },
    created: function () {
        getPatientRecord(this.$route.params.id).then((res) => {
            this.setPatientProfileData(res.data);
        });
    },
};
</script>

<style lang="scss" scoped>
header {
    @include flexr-between-center;
    padding: 16px;
    a,
    div {
        font-size: 24px;
        color: $black;
        font-weight: $semi-bold;
        text-decoration: none;
    }
}
</style>